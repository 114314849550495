<template>
  <!-- 发车时刻表2.0 -->
  <div class="departure-sdchedule" ref="departureSdchedule">
    <div class="function-buttons" ref="functionButtons" v-show="false"></div>
    <div class="form-area" ref="form">
      <search-form @getFormVal="search">
        <el-button
          type="primary"
          size="small"
          :disabled="tableData.length === 0"
          :loading="downLoadStatus"
          @click="exportData"
          >导出</el-button
        >
      </search-form>
    </div>
    <el-table
      :data="
        tableData.slice(
          (form.currentPage - 1) * form.pageSize,
          form.currentPage * form.pageSize
        )
      "
      stripe
      :height="tableHeight"
      width="100%"
    >
      <el-table-column label="序号" type="index" width="50"></el-table-column>
      <el-table-column
        prop="workTime"
        label="排班日期"
        width="110"
        :formatter="formatTime"
      ></el-table-column>
      <el-table-column prop="banCiName" label="班次"></el-table-column>
      <el-table-column prop="cph" label="车牌号" width="150">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.cph"
            remote
            collapse-tags
            placeholder="请选择"
            :reserve-keyword="false"
            @change="updaConfig($event, scope.row, 'cph')"
          >
            <el-option
              v-for="item in carOptions"
              :key="item.id"
              :label="item.cph"
              :value="item['vehicleNo']"
            ></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="driverName" label="司机" width="140">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.driverName"
            placeholder="请选择"
            @change="updaConfig($event, scope.row, 'driverName')"
          >
            <el-option
              v-for="item in driversOptions"
              :key="item.credentialsNo"
              :label="item.driverName"
              :value="item['credentialsNo']"
            ></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
        :label="head"
        v-for="(head, key, ele) in header"
        :key="ele"
      >
        <template slot-scope="scope">{{
          scope.row.checiList[key] ? scope.row.checiList[key].beginTime : ""
        }}</template>
      </el-table-column>
    </el-table>
    <!-- <div class="departure-main">      
      <div class="main-time-table">        
        <div class="timeline">
          <div
            v-for="time in timeLineData"
            :key="time"
            class="timeline-item"
            :style="{ width: timeWidth + 'px' }"
          >
            {{ time }}
          </div>
        </div>
        <div
          class="timeTable"
          :style="{
            width: timeWidth * timeLineData.length + 'px',
            height: tableHeight - 63 + 'px',
          }"
        >
          <div
            class="table-td"
            v-for="(tt, ti) in tableData.slice(
              (form.currentPage - 1) * form.pageSize,
              form.currentPage * form.pageSize
            )"
            :key="tt.banCiId"
          >
            <div
              :class="entry && entry.beginTime ? 'td-time' : ''"
              v-for="(entry, k) in tt.checiList"
              :key="k + 't'"
              :style="{
                left: computedLeft(entry) + 'px',
                top: entry && entry.moveType == 1 ? '12px' : '42px',
                background:
                  entry && entry.moveType == 1 ? '#05C46D' : '#FFC74B',
              }"
            >
              {{
                entry && entry.beginTime
                  ? entry.beginTime +
                    '-' +
                    getEndTime(entry.beginTime, entry.workTime)
                  : ''
              }}
            </div>
          </div>
        </div>
      </div> 
    </div> -->
    <div ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {
  querySchedulingListV2API,
  queryDriverByCompanyIdAndNameAPI,
  schedulingChangDriverAPI,
  schedulingChangVehicleAPI
} from "@/api/lib/bus-api";
import {
  addExcelExport,
  queryVehicleFuzzyNew2
} from "@/api/lib/refreshAlarm.js";
import { getCurent, formatDay } from "@/common/utils/index";
import searchForm from "./components/form.vue";
import moment from "moment";

export default {
  name: "departureSdchedule",
  components: {
    searchForm
  },
  data() {
    return {
      tableHeight: 0,
      tableData: [],
      form: {
        startTime: null,
        endTime: null,
        lineId: null,
        currentPage: 1,
        pageSize: 10
      },
      header: [],
      total: 0,
      exportForm: {}, //导出需要的数据
      downLoadStatus: false, //导出
      loading: false,
      carOptions: [],
      driversOptions: [],
      timeLineData: [], //时间轴
      timeWidth: 200 //时间轴宽度
    };
  },
  methods: {
    // 计算发车到达时间
    getEndTime(beginTime, workTime) {
      beginTime = moment().format("YYYY-MM-DD") + ` ${beginTime}`;
      let endTiem = moment(beginTime)
        .add(workTime, "m")
        .format("HH:mm");
      return endTiem;
    },
    computedLeft(entry) {
      let distance = 0;
      if (entry && entry.beginTime) {
        let startHour = Number(entry.beginTime.split(":")[0]);
        let startMin = Number(entry.beginTime.split(":")[1]);
        let index = this.timeLineData.findIndex(
          item => Number(item) == startHour
        );
        distance = (index + startMin / 60) * this.timeWidth;
      }
      return distance;
    },
    updaConfig(value, row, name) {
      const { credentialsNo, vehicleNo } = row;
      let data = {
        banciId: row.banCiId
      };
      console.log("配置修改参数：", data);
      let queryPath = null;
      if (name === "cph") {
        queryPath = schedulingChangVehicleAPI;
        data.vehicleNo = value;
      } else if (name === "driverName") {
        queryPath = schedulingChangDriverAPI;
        data.credentialsNo = value;
      }
      queryPath(data)
        .then(res => {
          if (res.code != 1000) {
            this.$message.warning(res.msg);
          }
          this.getDataList();
        })
        .catch(err => {});
      //请求接口保存数据
    },
    // 获取车辆数据
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          let data = {
            cph: query,
            companyId: this.form.lineId,
            deviceType: sessionStorage.getItem("system")
          };
          queryVehicleFuzzyNew2(data).then(res => {
            if (res.code === 1000) {
              this.carOptions = res.data;
            }
          });
        }, 200);
      } else {
        this.carOptions = [];
      }
    },
    //首次获取当前线路下的车辆数据、驾驶员数据
    firstCraData() {
      let data = {
        cph: "",
        companyId: this.form.lineId,
        deviceType: sessionStorage.getItem("system")
      };
      queryVehicleFuzzyNew2(data).then(res => {
        if (res.code === 1000) {
          this.carOptions = res.data;
        }
      });
      queryDriverByCompanyIdAndNameAPI({
        driverName: "",
        companyId: this.form.lineId
      }).then(res => {
        if (res.code == 1000) {
          this.driversOptions = res.data;
        }
      });
    },
    //模糊查询司机
    vehicleMethodFun(text) {
      queryDriverByCompanyIdAndNameAPI({
        driverName: text,
        companyId: this.form.lineId
      }).then(res => {
        if (res.code == 1000) {
          this.driversOptions = res.data;
        }
      });
    },
    // 计算表格高度
    computeHeight() {
      let wholeHeight = this.$refs.departureSdchedule.clientHeight;
      let buttonsHeight = this.$refs.functionButtons.clientHeight;
      let formHeight = this.$refs.form.clientHeight;
      let paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10;
    },
    //导出
    exportData() {
      this.downLoadStatus = true;
      let data = {
        baseUrl: "bus",
        fileName: `发车时刻表-${getCurent()}`,
        queryParam: JSON.stringify(this.exportForm),
        queryPath: "/schedulingManage/startTimeTableExport"
      };
      addExcelExport(data)
        .then(res => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    },
    // 提交
    search(val) {
      this.form = { ...this.form, ...val };
      this.firstCraData();
      this.getDataList();
    },

    // 获取列表
    getDataList() {
      let data = { ...this.form };
      delete data.currentPage;
      delete data.pageSize;
      this.header = [];
      this.tableData = [];
      querySchedulingListV2API(data).then(res => {
        if (res.code === 1000) {
          let flag = true;
          // 组装动态数据
          if (res.data.length > 0) {
            //取数组中长度最长的来设置表头
            let hearData = [...res.data];
            hearData
              .sort((a, b) => b.checiList.length - a.checiList.length)[0]
              .checiList.forEach(item => {
                this.header.push(flag ? "主站" : "副站");
                flag = !flag;
              });
            this.tableData = res.data;
            this.total = res.data.length;
          } else {
            this.tableData = [];
            this.total = 0;
          }
          /**
           * 静态页面测试数据
           */
          //最早时间 6:00 最晚时间23:40
          this.queryTimeLineData("06:00", "23:40");
        } else {
          this.tableData = [];
          this.total = 0;
          this.$message.error(res.msg);
        }
        this.exportForm = { ...data };
      });
    },
    //计算时间轴数据列表
    queryTimeLineData(start, end) {
      if (start && end) {
        let startHour = Number(start.split(":")[0]);
        let endHour = Number(end.split(":")[0]);
        let timeLineDataSingle = [];
        for (let i = startHour; i <= endHour + 1; i++) {
          timeLineDataSingle.push(i > 9 ? i : `0${i}`);
        }
        this.timeLineData = timeLineDataSingle;
      }
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.form.currentPage = page;
    },

    // 当前条数改变
    onSizeChange(size) {
      this.form.pageSize = size;
    },
    formatTime(row) {
      return formatDay(row.workTime);
    }
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  }
};
</script>

<style lang="scss" scoped>
.departure-sdchedule {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  .departure-main {
    display: flex;
    ::v-deep .el-table__row {
      height: 76px;
    }
    .main-time-table {
      width: calc(100% - 480px);
      overflow-x: auto;
      border-bottom: 1px solid #ebeef5;
      .timeline {
        width: min-content;
        border-bottom: 1px solid #ebeef5;
        height: 48px; //贴合table头部高度
        line-height: 48px;
        color: #999;
        font-size: 14px;
        font-weight: bold;
        display: flex;
        .timeline-item {
          // width: 120px;
          &::before {
            display: block;
            width: 100%;
            height: 6px;
            content: "";
            border-left: 1px solid #ebeef5;
            position: relative;
            left: 0;
            bottom: -42px;
          }
        }
      }
      .timeTable {
        .table-td {
          width: 100%;
          height: 75px;
          line-height: 75px;
          display: flex;
          position: relative;
          border-bottom: 1px solid #ebeef5;
          .td-time {
            position: absolute;
            padding: 0 6px;
            font-size: 10px;
            height: 24px;
            line-height: 24px;
            border-radius: 8px;
          }
        }
        .table-td:nth-child(even) {
          background-color: #f7f8f9;
        }
      }
    }
  }
}

.theme-project-bus {
  .departure-sdchedule {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
